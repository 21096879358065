@import "_generated-font-mixins.scss";
.wrapper {
  width: 100%;
  min-height: 52px;

  &.is__chatting {
    position: relative;
    opacity: 0.3;

    &::after {
      content: '';

      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
    }
  }
}

.phone__actions {
  display: flex;
  gap: 15px;
  height: 19px;
  font-size: 13px;

  & span {
    display: inline-block;

    line-height: 1.3;
    color: #005cc3;

    opacity: 0.7;
    border-bottom: dotted 1px;

    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }

    &:focus {
      outline: none;
    }

    &.phone__actions__delete {
      cursor: pointer;
      color: #b50009;

      &.mobile {
        position: absolute;
        right: 0;

        width: 20px;
        min-width: 20px;
        height: 20px;
        margin-left: 10px;

        font-size: 0;

        background: url('/static/images/delete-number.svg') center center no-repeat;
        border: 0;
      }
    }
  }

  &__verified {
    font-style: italic;
    color: #abb354;
  }

  &__verify {
    font-style: italic;
    color: #919599;
  }

  &__verification {
    cursor: pointer;
  }
}

.phone__item {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 10px;

  &.mobile {
    gap: 10px;

    & .phone__number {
      min-width: initial;
    }
  }
}

.phone__number {
  min-width: 145px;

  & label {
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    color: #919599;

    &.phone__number {
      color: #000;
    }
  }

  & input {
    margin-right: 10px;

    &:checked + label {
      color: #000;
    }

    &:disabled + label {
      cursor: not-allowed;
    }
  }
}
