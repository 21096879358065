@import "_generated-font-mixins.scss";
.moderate {
  position: absolute;
  z-index: 900;
  inset: 7px auto auto 119px;

  display: none;

  width: 280px;
  padding: 10px;

  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #fff;

  background: #005cc2;
  border-radius: 4px;
  box-shadow: 0 0 13px 1px rgb(0 0 0 / 20%);

  &::after {
    content: '';

    position: absolute;
    inset: 0 auto 0 -9px;

    width: 0;
    height: 0;
    margin: auto;

    border-color: transparent #005cc3 transparent transparent;
    border-style: solid;
    border-width: 10.5px 9px 10.5px 0;
  }

  &__active {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__message {
    text-align: center;
  }

  &__message > a {
    font-weight: 400;
    color: #fff;
    text-decoration: underline;
  }

  &__action {
    cursor: pointer;

    display: block;

    margin: 8px auto 0;

    font-size: 14px;
    font-weight: 400;
    color: #fff;
    text-align: center;
    text-decoration: underline;

    background: 0 0;
    border: none;
    outline: 0;
  }
}
