@import "_generated-font-mixins.scss";
/* stylelint-disable */

.upload {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 7px;
    position: relative;
    margin-right: 14px;
    width: 100%;
    aspect-ratio: 1;
    vertical-align: top;
    padding: 0;
    border: 1px solid #2d82eb;
    border-radius: 4px;
    box-sizing: border-box;
  }

  &__error {
    border: 1px solid red;
  }

  &__title {
    color: #333;
    display: flex;
    font-size: 15px;
    font-weight: 400;
    gap: 3px;
  }

  &__required {
    color: #be1910;
  }

  &__view {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    //background: #ededed;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    position: relative;

    &::after {
      @include icon-photo-plus;

      position: absolute;
      font-size: 28px;
      top: 55%;
      left: 46%;
      transform: translate(-50%, -50%);
      color: rgb(45, 130, 235);
    }
  }

  &__input {
    width: 0;
    position: absolute;
    height: 0;
    top: 0;
    left: 0;
    //visibility: hidden;
    //display: ;
  }
}
