@import "_generated-font-mixins.scss";
.wrapper__price {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  font-size: 14px;
  font-weight: 400;
  color: #333;
  letter-spacing: -0.42px;

  &.mobile {
    & .price {
      font-size: 15px;
    }

    & .info {
      font-size: 15px;
      font-weight: 100;
    }
  }
}

.slider {
  display: block !important;
  flex: 1 0 auto;
  width: 135px !important;
  margin-right: 10px;
}

.price {
  margin-right: 7px;

  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
  color: #333;
  white-space: nowrap;

  &__thin {
    font-weight: 300;
  }
}

.item {
  position: relative;
  overflow: hidden;
  width: 50%;
  margin-bottom: 20px;

  &.large__ad {
    width: 100%;
  }

  &.item__empty {
    min-height: 162px;
  }

  & > a {
    &:visited {
      color: #999;
    }
  }

  &__thumbnail {
    position: relative;

    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 129px;
    margin-bottom: 10px;

    background-color: #dedede;
    border-radius: 4px;

    &__slider {
      height: 105px;
    }

    & img {
      width: 100%;
    }

    &.large__ad {
      height: auto;
    }

    &.gradient {
      &::after {
        content: '';

        position: absolute;
        inset: 0;

        background: url('/static/images/gradient-frame.svg') center center no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  &__image {
    height: auto;

    &.large__ad {
      background-clip: content-box, border-box;
      background-origin: border-box;
      border: 4px solid;
      border-image-source: linear-gradient(120.17deg, #fcae29 -0.2%, #73b223 99.11%);
      border-image-slice: 1;
    }
  }

  &__hide {
    visibility: hidden;
  }

  &__title {
    overflow: hidden;

    max-height: 18px;
    margin-bottom: 5px;

    font-size: 15px;
    line-height: 18px;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;

    background-color: #dedede;
    border-radius: 4px;

    &__slider {
      background-color: transparent;

      & span {
        background-color: #dedede;
        border-radius: 4px;
      }
    }
  }

  &__booster {
    position: absolute;
    top: 5px;
    left: 5px;

    &.large__ad {
      top: 10px;
      left: 10px;
    }

    & [data-tooltip-id] {
      width: 19px;
      height: 18px;

      &::after {
        font-size: 18px;
      }
    }
  }
}

.container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 48%;
}
