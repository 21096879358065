@import "_generated-font-mixins.scss";
.contacts {
  display: flex;
  flex-direction: column;

  &__box {
    display: flex;
    align-items: center;
  }

  &__title {
    margin: 5px 8px 0 0;
    font-size: 17px;
    font-weight: 700;
    color: #a2a6ab;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-content: center;
  }

  &__show {
    margin-top: 19px;

    &__hidden {
      visibility: hidden;
    }
  }

  &__without {
    display: flex;
    gap: 8px;
    align-items: center;

    margin: 5px 0 0;

    font-size: 17px;
    font-weight: 700;
    color: #a2a6ab;

    & i {
      font-size: 17px;
      color: #a2a6ab;
    }
  }
}
