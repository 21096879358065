@import "_generated-font-mixins.scss";
.switcher__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  height: 52px;
  padding: 0 10px 0 0;

  background-color: #fff;

  &.disabled {
    opacity: 0.5;
    filter: grayscale(90%);
  }

  &__icon {
    width: 33px;
    color: #b8b8b8;
    text-align: center;
  }

  &__actions {
    margin-left: 10px;
  }

  &__title {
    overflow: hidden;

    max-width: 99%;
    margin-bottom: 2px;

    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: #b8b8b8;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__body {
    display: flex;
    align-items: center;
    width: calc(100% - 64px);
    text-decoration: none;

    &.filter {
      & .switcher__item__title {
        color: #b8b8b8;
      }

      & .switcher__item__icon {
        color: #b8b8b8;
      }

      &.active {
        & .switcher__item__title {
          color: #2d82eb;
        }

        & .switcher__item__icon {
          color: #2d82eb;
        }
      }
    }

    &.subscribe {
      & .switcher__item__title {
        color: #2d82eb;
      }

      & .switcher__item__icon {
        color: #2d82eb;
      }

      &.active {
        & .switcher__item__title {
          color: #333;
        }

        & .switcher__item__icon {
          color: #72b222;
        }
      }
    }
  }
}
