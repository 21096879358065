@import "_generated-font-mixins.scss";
.checkbox {
  &__wrapper {
    display: flex;
    align-items: flex-start;

    & input {
      cursor: pointer;
    }

    & .icon {
      &__plus {
        &::before {
          @include icon-plus-sign;
        }
      }

      &__minus {
        &::before {
          @include icon-minus-sign;
        }
      }

      &__plus,
      &__minus {
        margin-top: 3px;

        &::before {
          cursor: pointer;
          margin-right: 10px;
          font-size: 16px;
          color: #007aff;
        }
      }

      &__down {
        &::before {
          @include icon-arrow-down;
        }
      }

      &__up {
        &::before {
          @include icon-arrow-up;
        }
      }

      &__down,
      &__up {
        margin: 5px 5px 0;
        margin-left: 3px;
        line-height: 1;
        vertical-align: middle;

        &::before {
          cursor: pointer;
          font-size: 13px;
          line-height: 1;
        }
      }
    }

    &.mobile {
      align-items: center;
    }
  }
}
