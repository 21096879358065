@import "_generated-font-mixins.scss";
.drawer {
  position: fixed;
  z-index: 997;
  inset: 0 0 0 100%;

  display: flex;
  flex-direction: column;

  width: 100%;
  padding-bottom: 51px;

  visibility: hidden;
  background: #fff;

  &__opened {
    left: 0;
    overflow-y: auto;
    visibility: visible;
  }

  &__overlay {
    z-index: 1000;
  }

  &.animate {
    transition: all 0.35s;
  }

  &__header {
    display: flex;
    height: 48px;
    background: #ececef;

    &.sticky {
      position: sticky;
      z-index: 9999;
      top: 0;
    }

    &__background {
      background: #ececef;
    }
  }

  &__close {
    width: 48px;
    height: 48px;
    text-align: center;

    &::after {
      @include icon_cancel;

      font-size: 19px;
      line-height: 48px;
      color: #6e6e6e;
    }
  }

  &__back {
    width: 48px;
    height: 48px;
    text-align: center;

    &::after {
      @include icon_arrow-back;

      font-size: 19px;
      line-height: 48px;
      color: #6e6e6e;
    }
  }

  &__title {
    flex: 1 0 auto;

    padding: 16px 0 16px 48px;

    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    color: #333;
    text-align: center;
  }

  &__subtitle {
    padding: 20px 16px;

    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    color: #333;
  }
}
