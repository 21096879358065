@import "_generated-font-mixins.scss";
/* stylelint-disable */

.input {
  &__container {
    display: flex;
    gap: 7px;
    position: relative;
    font-size: 15px;
    min-height: 50px;
    padding: 7px 0;
    border-bottom: solid 1px #e2e2e2;
    box-sizing: border-box;

    &__vertical {
      flex-direction: column;
    }

    &__horizontal {
      flex-direction: row;
    }

    &__error {
      & .input__label {
        color: red;
      }

      & .input__input {
        border-color: red;
      }
    }
  }

  &__label {
    display: block;
    margin-bottom: 3px;
    color: rgba(51, 51, 51, 0.6);
    font-size: 13px;
    line-height: 15px;
    gap: 3px;

    &__capp {
      font-size: 15px;
      font-weight: 700;
      line-height: 17px;
      margin: 0 0 16px;
      color: #333;
    }
  }

  &__required {
    color: #be1910;
  }

  &__head {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__tooltip {
    font-size: 13px;
    font-weight: 400;
    line-height: 16.9px;
    text-align: left;
    color: #666666;
  }

  &__capitalize {
    display: flex;
    align-items: center;
    background: #f3f3f3;
    border-radius: 4px;
    width: fit-content;

    &__button {
      padding: 4px 10.5px;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #7d7d7d;
      cursor: pointer;
      border: none;
      background: 0 0;
    }
  }

  &__input {
    color: #333;
    font-size: 15px;
    margin-bottom: 3px;
    min-height: 36px;

    box-sizing: border-box;
    height: 40px;
    width: 100%;
    padding: 0 14px;
    border: 1px solid #ccc;
    border-radius: 6px;
    background-color: #fff !important;

    &:focus {
      border: 1px solid #ced3d3;
    }
  }

  &__box {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  &__error {
    bottom: 2px;
    color: red;
    font-size: 12px;
    font-weight: 400;
    //position: absolute;
  }
}
