@import "_generated-font-mixins.scss";
.form {
  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 100%;
  }

  &__body {
    @media screen and (width <= 640px) {
      gap: 8px;
    }

    display: flex;
    flex-direction: column;
    gap: 40px;

    width: 100%;
    max-width: 1000px;
  }

  &__submit {
    width: 100%;
    padding: 18px 40px;

    font-size: 20px;
    font-weight: 700;
    line-height: 1;
    color: #fff;

    background: #315dbb;
    border-radius: 4px;

    &:disabled {
      cursor: default;
      color: #b9b9b9;
      background: #efefef;
    }
  }
}

.agreement__error {
  margin-left: 20px;
  font-size: 12px;
  color: #ef4926;
}
