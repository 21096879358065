@import "_generated-font-mixins.scss";
.slider {
  &__item {
    width: 320px !important;
    height: 240px;
    margin: 0 10px 5px 0;

    &__full {
      width: 100% !important;
      margin: 0 !important;
    }
  }

  &__content {
    @media screen and (width <= 640px) {
      border-radius: 0;
    }

    cursor: zoom-in;

    position: relative;

    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    font-size: 30px;
    color: #000;

    border-radius: 4px;
  }

  &__thembs {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    &.mobile {
      margin: 10px;
    }
  }

  &__thumb {
    position: relative;

    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    width: 100%;
    height: auto;
  }

  &__thumb__image {
    cursor: pointer;

    position: relative;

    overflow: hidden;

    width: 100%;
    max-width: 64px;
    height: 48px;

    background: #000;
    border-radius: 4px;
  }

  &__thumb__video {
    position: relative;

    overflow: hidden;

    width: 100%;
    max-width: 138px;
    height: 75px;

    background: #000;
    border-radius: 4px;

    &:hover {
      opacity: 0.9;

      & .icon {
        border-color: #fff;
      }
    }

    & .icon {
      position: absolute;
      top: 50%;
      left: 50%;

      width: 50px;
      height: 50px;
      margin: -24px 0 0 -26px;

      background-color: rgb(0 0 0 / 40%);
      border: 1px solid transparent;
      border-radius: 50%;

      &::after {
        @include icon-play-icon;

        position: absolute;
        top: 0;
        left: 9px;

        font-size: 33px;
        color: #fff;

        opacity: 0.9;
      }
    }

    &__mobile {
      position: relative;

      width: calc(20% - 10px);
      height: 63px;

      object-fit: cover;
      object-position: center;

      & .icon {
        position: absolute;
        top: 50%;
        left: 50%;

        width: 35px;
        height: 35px;
        margin: -17px 0 0 -17px;

        background-color: rgb(0 0 0 / 40%);
        border-radius: 50%;

        &::after {
          @include icon-play-icon;

          position: absolute;
          top: 3px;
          left: 9px;

          font-size: 18px;
          color: #fff;

          opacity: 0.9;
        }
      }
    }
  }

  &__container {
    @media print {
      max-width: 1000px;
    }

    overflow: hidden;
    max-width: 720px;

    &.mobile {
      max-width: 100%;
      min-height: 247px;
    }

    & .slider li {
      list-style-type: none;
    }
  }
}
