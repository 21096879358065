@import "_generated-font-mixins.scss";
.collapse {
  @media screen and (width <= 992px) {
    background: #fff;
  }

  width: 100%;

  &__summary {
    @media screen and (width <= 992px) {
      cursor: pointer;

      position: relative;

      margin: 0;
      padding: 24px 16px;

      font-size: 21px;
      font-weight: 700;
      color: #555;
      letter-spacing: 1px;

      background: 0 0;
      outline: 0;
      box-shadow: none;
    }

    position: relative;

    margin-left: -15px;
    padding-left: 15px;

    font-size: 16px;
    font-weight: 700;
    color: #0058c5;
    list-style: none;

    appearance: none;
    background-image: none;

    /* stylelint-disable */
    &::-webkit-details-marker {
      display: none;
    }

    &::before {
      @media screen and (width <= 992px) {
        content: '\ea0f';
        font-family: '999-icon-font' !important;
        position: absolute;
        color: #555;
        right: 15px;
        width: auto;
        height: fit-content;
        font-size: 15px;
        border: 0;
        transform: none;
        top: 0;
        bottom: 0;
        left: auto;
        margin: auto;
      }

      content: '';

      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      left: 0.5px;
      transform: translateY(-1.5px) rotate(-90deg);

      display: flex;

      width: 0;
      height: 0;
      margin: auto;

      border-top: 7px solid #0058c5;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }

    &__without {
      padding: 24px 0;

      &::before {
        @media screen and (width <= 992px) {
          right: 0;
        }
      }
    }
  }

  &[open] &__summary {
    @media screen and (width <= 992px) {
      margin-bottom: 0;
      padding-bottom: 16px;
    }

    margin-bottom: 17px;

    &::before {
      @media screen and (width <= 992px) {
        transform: rotate(180deg);
      }
      transform: rotate(0);
    }
  }
}
