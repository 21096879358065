@import "_generated-font-mixins.scss";
.modal {
  margin: 0 10px;
  padding: 0;
}

.republish__modal__wrapper.desktop {
  padding-top: 30px;

  & .republish__modal {
    &__header {
      & h3 {
        font-size: 21px;
        font-weight: 700;
        line-height: 30px;
      }
    }

    &__body {
      & > div {
        margin-bottom: 10px;
        font-size: 14px;
        color: #000;
        letter-spacing: 0;
      }

      &__help {
        font-size: 11px;
        line-height: 15px;
        color: #000;
        letter-spacing: 0;

        opacity: 0.7;
      }

      &__label {
        font-size: 14px;
        color: #000;
        letter-spacing: 0;
      }
    }
  }

  & .republish__modal__footer {
    flex-direction: row-reverse;
    padding-bottom: 15px;
  }
}

.republish__modal {
  &__wrapper {
    position: relative;

    overflow: hidden;

    box-sizing: border-box;
    width: auto;
    max-width: 430px;
    padding-top: 17px;

    text-align: left;

    background: #fff;
    border-radius: 4px;

    transition: filter 0.4s;
  }

  &__header {
    margin-bottom: 20px;
    padding: 0 20px;

    & h3 {
      font-size: 16px;
      font-weight: 700;
      line-height: 18px;
      color: #000;
      letter-spacing: 0.5px;
    }
  }

  &__body {
    margin-bottom: 20px;
    padding: 0 20px;

    font-size: 16px;
    line-height: 18px;
    color: #767676;
    letter-spacing: 0.5px;

    &__help {
      margin-bottom: 10px;
      font-size: 11px;
      opacity: 0.7;
    }

    &__label {
      display: flex;
      align-items: center;

      & input {
        margin: 0 12px 0 0;
      }
    }

    & p {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  &__footer {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: flex-end;

    padding: 0 20px 7px;
  }
}
