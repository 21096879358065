@import "_generated-font-mixins.scss";
.subcategory {
  &__title {
    display: block;

    margin-bottom: 5px;

    font-size: 17px;
    font-weight: 700;
    color: #a2a6ab;
    letter-spacing: -0.5pt;
  }
}

.footer {
  width: 720px;
  margin-top: 35px;
  margin-bottom: 35px;
}

.price {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  letter-spacing: -0.5pt;

  &__title {
    margin-right: 5px;
  }
}

.price__title,
.region__title {
  align-self: flex-end;

  font-size: 17px;
  font-weight: 700;
  line-height: 1;
  color: #a2a6ab;
}

.region {
  max-width: 550px;
  font-size: 21px;
  font-weight: 700;

  &__title {
    margin-right: 5px;
  }
}
