@import "_generated-font-mixins.scss";
.description {
  &__box {
    display: flex;
    flex-direction: column;
    max-width: 410px;
  }

  &__main {
    font-size: 13px;
  }

  &__action {
    margin-left: 1px;
    font-size: 13px;
    font-weight: 700;
    color: #005cc3;

    &:hover {
      color: #c01;
    }
  }
}
