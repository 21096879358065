@import "_generated-font-mixins.scss";
.wrapper {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-around;

  width: 100%;
  height: 32px;

  font-size: 12px;
  color: #999;

  border-bottom: 1px solid #f5f5f5;

  &:hover {
    background-color: #fafafa;
  }
}

.title {
  position: relative;

  overflow: hidden;
  flex: 1;
  align-content: center;

  height: 32px;
  padding: 0 5px;

  text-overflow: ellipsis;
  white-space: nowrap;

  border-color: transparent;

  &__link {
    color: #000;

    &:hover {
      color: #c01;
    }

    &:visited {
      color: #9330b3;
    }
  }
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 18px;
  height: 32px;
  margin-right: 5px;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 18px;
    height: 18px;

    &::after {
      @include icon-photo-camera;

      transform: translateY(-2px);

      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 100%;

      font-size: 12px;
      line-height: 1;
      color: #aeaeae;
    }
  }
}

.favorite {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 28px;
  height: 32px;

  &__empty {
    &::before {
      @include icon-heart;

      font-size: 13px;
      color: #555;
    }

    &:hover {
      &::before {
        opacity: 0.7;
      }
    }
  }

  &__fill {
    &::before {
      @include icon-fill-heart;

      font-size: 13px;
      color: #ef4726;
    }

    &:hover {
      &::before {
        opacity: 0.8;
      }
    }
  }
}

.year {
  position: relative;

  align-content: center;

  width: 70px;
  height: 32px;
  padding: 0 5px;

  text-align: center;
}

.volume {
  position: relative;

  align-content: center;

  width: 90px;
  height: 32px;
  padding: 0 5px;

  text-align: center;
}

.distance {
  position: relative;

  align-content: center;

  width: 90px;
  height: 32px;
  padding: 0 5px;

  text-align: center;
}

.transmission {
  position: relative;

  align-content: center;

  width: 50px;
  height: 32px;
  padding: 0 5px;

  text-align: center;
}

.price {
  position: relative;

  overflow: hidden;
  align-content: center;

  min-width: 91px;
  height: 32px;
  padding: 0 5px;

  font-size: 14px;
  font-weight: 700;
  color: #6c3f80;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;

  &__size {
    font-size: 12px;
  }

  &__thin {
    font-weight: 300;
  }
}

.date {
  position: relative;

  align-content: center;

  width: 112px;
  height: 32px;
  padding: 0 5px;

  font-size: 11px;
  text-align: right;
}

.tooltip {
  --rt-opacity: 1;

  z-index: 5;
  background-color: transparent !important;

  &__image {
    border-radius: 4px;

    & img {
      border-radius: inherit;
    }
  }
}

.booster {
  position: relative;
  width: 30px;
  height: 18px;

  &__item {
    position: absolute;
    top: 0;
    left: 7px;
  }
}
