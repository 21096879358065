@import "_generated-font-mixins.scss";
.item {
  position: relative;
  overflow: hidden;
  width: 48%;
  margin-bottom: 20px;

  &.large__ad {
    width: 100%;
  }

  &.item__empty {
    min-height: 162px;
  }

  & > a {
    &:visited {
      color: #999;
    }
  }

  &__thumbnail {
    position: relative;

    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 129px;
    margin-bottom: 10px;

    border-radius: 4px;

    & img {
      width: 100%;
    }

    &.large__ad {
      height: auto;
    }

    &.slider {
      height: 100px;
    }

    &.gradient {
      &::after {
        content: '';

        position: absolute;
        inset: 0;

        background: url('/static/images/gradient-frame.svg') center center no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  &__image {
    height: auto;

    &.large__ad {
      background-clip: content-box, border-box;
      background-origin: border-box;
      border: 4px solid;
      border-image-source: linear-gradient(120.17deg, #fcae29 -0.2%, #73b223 99.11%);
      border-image-slice: 1;
    }
  }

  &__hide {
    visibility: hidden;
  }

  &__title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;

    max-height: 54px;
    margin-bottom: 5px;

    font-size: 15px;
    line-height: 18px;
    text-decoration: none;
    text-wrap: pretty;

    -webkit-line-clamp: 3;
  }

  &__booster {
    position: absolute;
    top: 5px;
    left: 5px;

    &.large__ad {
      top: 10px;
      left: 10px;
    }

    & [data-tooltip-id] {
      width: 19px;
      height: 18px;

      &::after {
        font-size: 18px;
      }
    }
  }
}

.container {
  position: relative;
}
