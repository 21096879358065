@import "_generated-font-mixins.scss";
/* stylelint-disable */

.upload {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 7px;
    position: relative;
    grid-column-start: 1;
    grid-column-end: 4;
    margin-right: 14px;
  }

  &__title {
    color: #333;
    display: flex;
    font-size: 15px;
    font-weight: 400;
    gap: 3px;
  }

  &__required {
    color: #be1910;
  }

  &__view {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ededed;
    height: 75px;
    width: 100px;
    opacity: 0.6;
    border-radius: 6px;
    position: relative;
    cursor: pointer;

    & img {
    }

    &__error {
      border: 1px solid red;
    }
  }

  &__input {
    width: 0;
    position: absolute;
    height: 0;
    top: 0;
    left: 0;
    opacity: 0;
    //visibility: hidden;
    //display: ;
  }
}
