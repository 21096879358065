@import "_generated-font-mixins.scss";
.footer {
  display: flex;
  color: #000;

  &__from {
    font-size: 29px;
    font-weight: 100;
    line-height: 25px;
    color: #000;
  }

  &__main {
    font-size: 29px;
    line-height: 25px;

    &:first-child {
      font-size: 21px;
      font-weight: 700;
      line-height: 18px;
      color: #000;
    }
  }

  &__service {
    margin-left: 5px;
    font-size: 29px;
    font-weight: 400;
    line-height: 25.3px;
  }

  &__converted {
    display: flex;
    align-items: center;

    font-size: 18px;
    font-weight: 700;
    line-height: 16px;
    color: #b6b6b6;

    & li {
      cursor: default;

      display: flex;

      margin-left: 15px;

      font-size: 18px;
      font-weight: 700;

      &::before {
        content: '/';

        margin-right: 15px;

        font-size: 30px;
        font-weight: 400;

        opacity: 0.5;
      }
    }
  }
}

.sidebar {
  min-height: 50px;
  color: #000;

  &__from {
    margin-left: 0;
    font-size: 21px;
    font-weight: 400;
    color: #000;
  }

  &__main {
    margin-left: 5px;
    font-size: 22px;
    font-weight: 700;
    line-height: 25.3px;

    &:first-child {
      margin-left: 0;
      font-size: 21px;
      font-weight: 700;
      color: #000;
    }
  }

  &__service {
    font-size: 22px;
    font-weight: 400;
    line-height: 25.3px;
  }

  &__converted {
    display: flex;

    min-height: 16px;
    padding: 8px 0 0;

    font-size: 14px;
    line-height: 16px;
    color: #ccc;

    & li {
      font-weight: 700;

      &:nth-of-type(2) {
        display: flex;
        margin-left: 10px;

        &::before {
          content: '' !important;

          width: 1px;
          height: 16px;
          margin-right: 10px;

          font-weight: 400;

          background: #ccc;
        }
      }
    }
  }
}

.footer,
.sidebar {
  &__main {
    font-weight: 700;
  }

  &__tooltip {
    font-size: 14px !important;
    font-weight: normal;
    background-color: #000 !important;
  }
}

.blur {
  filter: blur(2px);
}

.negotiable {
  color: #000;

  &__main {
    margin-left: 5px;
    font-size: 22px;
    font-weight: 700;
    line-height: 25.3px;
  }
}
