@import "_generated-font-mixins.scss";
.adlist {
  &__container {
    &.flex {
      display: flex;
      flex-flow: row wrap;
    }

    &.mobile {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0;

      & > button {
        margin: 0 10px;
      }
    }
  }

  &.flex {
    display: flex;
    flex-flow: row wrap;

    min-height: 240px;
    margin-right: -20px;
    margin-bottom: 20px;
  }

  &__admix {
    position: relative;

    overflow: hidden;

    width: auto;
    max-width: 253px;
    height: 240px;

    &.mobile {
      width: 100%;
      max-width: initial;
      height: initial;
      margin: 20px 0;

      font-size: 0;
      text-align: center;
    }

    &__label {
      position: absolute;
      z-index: 9;
      top: 0;
      left: 0;

      padding: 4px 6px;

      font-size: 7px;
      font-weight: 700;
      line-height: 7px;
      color: #58585b;
      text-align: center;

      background: rgb(255 255 255 / 70%);
      border-radius: 4px 0 0;
    }
  }

  &.profile {
    min-height: 200px;
  }

  &__placeholder {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    width: 100%;
    min-height: 240px;
  }

  &.mobile {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    margin-top: 0;
    padding: 0 10px;

    &__single {
      width: 48%;

      & a {
        width: 100%;
      }
    }

    & > div {
      margin-bottom: 20px;
    }
  }
}
