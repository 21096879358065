@import "_generated-font-mixins.scss";
.ad {
  &__container {
    position: relative;
    width: 1000px;
    margin: 0 auto;

    &.mobile {
      @media screen and (width >= 640px) {
        margin: 10px;
      }

      width: 100%;
    }
  }
}

.archive {
  @media screen and (width <= 640px) {
    width: 100%;
    margin: 0 0 20px;
    line-height: 16px;
  }

  width: calc(100% - 22px);
  margin-top: 10px;
  padding: 11px;

  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  color: #bd4a48;
  text-align: center;

  background-color: #fef4f4;
  border: solid 1px #e4c4c4;
  border-radius: 4px;
}

.title {
  @media screen and (width <= 640px) {
    margin: 20px 0 0 15px;
    padding: 0;

    font-size: 23px;
    font-weight: 500;
    color: #555;
  }

  padding: 30px 0;

  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  color: #000;
}

.restricted {
  @media screen and (width <= 640px) {
    margin: 15px;
    font-size: 16px;
    color: #555;
  }

  & h2 {
    @media screen and (width <= 640px) {
      margin: 25px 0 20px;
      font-weight: bold;
    }

    margin: 25px 0 20px;
    font-weight: bold;
  }

  & b {
    @media screen and (width <= 640px) {
      font-weight: 400;
    }

    font-weight: 700;
  }

  & p {
    margin: 20px 0 0;
    color: #959595;

    & a {
      @media screen and (width <= 640px) {
        color: #333;
        text-decoration: underline;
      }

      color: #005cc3;
      text-decoration: none;
    }
  }
}
