@import "_generated-font-mixins.scss";
.image__box {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  aspect-ratio: 1;
  width: 100%;

  background: #fff;
  border-radius: 4px;

  &__container {
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 7px;

    margin-bottom: 20px;
  }

  &__required {
    color: #be1910;
  }

  &__label {
    position: relative;

    display: flex;
    gap: 3px;
    align-items: center;

    margin-bottom: 16px;

    font-size: 15px;
    font-weight: 700;
    line-height: 17px;
    color: #333;
  }

  &__count {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;

    font-size: 13px;
    font-weight: 400;
    font-style: italic;
    line-height: 1;
    color: #999;
    text-align: right;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;

    &__empty {
      z-index: 1;

      overflow: hidden;

      box-sizing: border-box;
      aspect-ratio: 1;
      width: 100%;

      opacity: 0.5;
      border: 1px dashed #2d82eb;
      border-radius: 4px;
    }
  }

  &__play {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 40px;
    height: 40px;
    margin: -20px 0 0 -20px;

    vertical-align: middle;

    opacity: 0.8;
    background-image: url('/static/sprite.png');
    background-position: 0 -680px;
  }

  &__remove {
    cursor: pointer;

    position: absolute;
    z-index: 1;
    top: 4px;
    right: 4px;

    width: 14px;
    height: 14px;

    opacity: 0.4;
    background-color: rgb(0 0 0);
    border-radius: 50%;

    &::after {
      @include icon-cancel;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      font-size: 7.5px;
      color: #fff;
    }
  }

  &__move {
    cursor: pointer;

    position: absolute;
    top: 0;
    left: 0;

    display: none;
    align-items: center;
    justify-content: center;

    width: 22px;
    height: 22px;

    font-size: 16px;
    font-weight: 400 !important;
    line-height: 22px;
    color: #fff;

    opacity: 0.7;
    background: #000;
    border-top-left-radius: 4px;
  }

  &:hover .image__box__move {
    display: flex;
  }
}
