@import "_generated-font-mixins.scss";
.card {
  background-color: #fff;

  &:not(:last-child) {
    border-bottom: solid 1px #ccc;
  }

  & td {
    height: 40px;
    padding: 15px 10px;
    text-align: left;
    vertical-align: middle;
  }

  &.blocked {
    background-color: #fff5f5;
  }
}

.title {
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: bold;
  border-right: dotted 1px #ccc;

  & h4 {
    width: 200px;
    margin-bottom: 8px;
    font-weight: 700;
    word-break: break-all;

    & a {
      color: #007bff;

      &:hover {
        color: #c01;
        border-bottom: solid 1px;
      }
    }
  }
}

.category {
  font-size: 14px;
  color: #666;
}

.date {
  height: 40px;
  padding: 15px 10px;

  font-size: 11px;
  color: #444;
  white-space: nowrap;
  vertical-align: middle;

  &:last-child {
    padding-left: 10px;
    text-align: center;
  }
}

.breadcrumbs {
  margin-bottom: 0;
  font-size: 11px;
  opacity: 0.6;
}

p.blocked {
  margin-bottom: 0;

  font-size: 11px;
  font-weight: bold;
  color: red;

  opacity: 0.6;
}
