@import "_generated-font-mixins.scss";
.label {
  &__wrapper {
    position: absolute;
    bottom: 8px;
    left: 8px;
    font-size: 0;
  }

  &__custom {
    position: relative;

    display: inline-block;

    box-sizing: border-box;
    height: 19px;
    margin-top: 6px;
    margin-right: 5px;
    padding: 4px 6px;

    font-size: 11px;
    line-height: 10px;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: top;

    background-color: rgb(0 0 0 / 27%);
    border-radius: 2px;

    &.only__text {
      line-height: 12px;
    }
  }

  &__one__color {
    display: inline-block;

    margin-top: 6px;
    padding: 2px 6px;

    font-size: 10px;
    line-height: 15px;
    color: #fff;

    border-radius: 2px;
  }

  &__gradient {
    height: 19px;
    padding: 4px 8px;

    font-size: 10px;
    color: #fff;

    border-radius: 2px;
  }
}

.icon__engine {
  margin-right: 6.5px;

  &::before {
    @include icon-engine;

    font-size: 10px;
    color: #fff;
  }
}

.icon__transmission {
  margin-right: 5px;

  &::before {
    @include icon-shift;

    font-size: 10px;
    color: #fff;
  }
}

.icon__estate {
  position: relative;
  margin-right: 9px;
  margin-left: 11px;
  line-height: 1px;

  &::before {
    @include icon-estate;

    position: absolute;
    top: 6px;
    left: -6px;

    width: 11px;
    height: 11px;

    font-size: 11px;
    line-height: 1px;
    color: #fff;
  }
}

.one__color__wrapper {
  display: flex;
  flex-direction: column;
}
