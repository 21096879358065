@import "_generated-font-mixins.scss";
.chat__wrapper {
  overflow: hidden;

  max-height: 500px;
  max-height: 0;

  opacity: 1;
  background: #d5d5d5;

  transition: max-height 0.4s;

  &.visible {
    max-height: 500px;
  }

  &__form {
    padding: 20px 10px 27px;

    &__textarea {
      position: relative;
      margin-bottom: 5px;

      &__input {
        resize: none;

        box-sizing: border-box;
        width: 100%;
        height: 100px;
        padding: 14px 10px;

        background: #fff;
        border: 1px solid #e2e2e2;
        border-radius: 4px;
      }
    }

    &__button {
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
      padding: 8px;

      font-size: 15px;
      color: #fff;

      background: #0058c5;
      border: none;
      border-radius: 4px;

      transition: background-color 0.3s;

      &.green {
        background: #abb354;
      }

      &.red {
        background: #c01;
      }
    }
  }
}

.error {
  position: absolute;
  right: 12px;
  bottom: 8px;

  font-size: 14px;
  color: #ef4926;
}
