@import "_generated-font-mixins.scss";
.checkbox {
  &__container {
    position: relative;

    display: flex;
    gap: 7px;
    align-items: center;

    box-sizing: border-box;
    min-height: 50px;
    padding: 7px 0;

    font-size: 15px;

    border-bottom: solid 1px #e2e2e2;

    &__vertical {
      flex-direction: column;
    }

    &__horizontal {
      flex-direction: row;
    }
  }

  &__label {
    cursor: pointer;

    position: relative;

    display: flexbox;
    display: flex;
    gap: 7px;
    align-items: center;
    justify-content: space-between;

    box-sizing: border-box;
    width: 100%;
    min-height: 35px;

    font-size: 15px;
    color: #333;

    background: 0 0;
    outline: 0;
    box-shadow: none;

    -ms-flex-align: center;
    -ms-flex-pack: justify;

    & label {
      cursor: pointer;
      display: flex;
      flex: 1;
    }

    & a {
      color: #005cc3;
      text-decoration: none;

      &:hover {
        color: #c01;
      }
    }

    & input {
      width: 20px;
      height: 20px;
    }
  }

  &__required {
    margin-left: 4px;

    font-size: 20px;
    font-weight: 400;
    line-height: 13px;
    color: #b50009;
  }
}
