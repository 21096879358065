@import "_generated-font-mixins.scss";
.wrapper {
  position: relative;
  display: flex;
  gap: 14px;
  padding-bottom: 20px;
}

.image {
  display: block;

  width: 100%;
  max-width: 95px;
  height: 100%;
  max-height: 65px;

  & img {
    width: auto;
  }
}

.title {
  display: flex;
  font-size: 16px;

  &__link {
    margin-right: 10px;
    line-height: 20px;
    color: #005cc3;

    &:hover {
      color: #c01;
    }

    &:visited {
      color: #9330b3;
    }
  }
}

.description {
  width: 640px;
  margin-bottom: 3px;

  font-size: 13px;
  line-height: 135%;
  color: #242424;
  word-wrap: break-word;
  vertical-align: bottom;
}

.price {
  flex: 0;

  margin-right: 7px;

  font-size: 13px;
  font-weight: 700;
  line-height: 15px;
  color: #333;
  white-space: nowrap;
}

.favorite {
  display: flex;

  &__empty {
    &::before {
      @include icon-heart;

      font-size: 13px;
      line-height: 16px;
      color: #555;
    }

    &:hover {
      &::before {
        opacity: 0.7;
      }
    }
  }

  &__fill {
    &::before {
      @include icon-fill-heart;

      font-size: 13px;
      line-height: 16px;
      color: #ef4726;
    }

    &:hover {
      &::before {
        opacity: 0.8;
      }
    }
  }
}

.booster {
  position: absolute;
  top: 8px;
  left: 8px;
}
