@import "_generated-font-mixins.scss";
.textarea {
  &__container {
    position: relative;
    display: flex;
    gap: 7px;
    padding: 20px 0;

    &__vertical {
      flex-direction: column;
    }

    &__horizontal {
      flex-direction: row;
    }

    &__error {
      & .textarea__label {
        color: red;
      }

      & .textarea__count {
        color: red;
      }

      & .textarea__input {
        border-color: red;
      }
    }
  }

  &__label {
    position: relative;

    gap: 3px;

    font-size: 15px;
    font-weight: 700;
    line-height: 17px;
    color: #333;
  }

  &__required {
    color: #be1910;
  }

  &__head {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-bottom: 16px;
  }

  &__tooltip {
    font-size: 13px;
    font-weight: 400;
    line-height: 16.9px;
    color: #666;
    text-align: left;
  }

  &__input {
    resize: vertical;

    box-sizing: border-box;
    width: 100%;
    height: 92px;
    min-height: 36px;
    padding: 10px;

    font-size: 15px;
    color: #333;

    appearance: none;
    background: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 4px;

    &:focus {
      border: 1px solid #ced3d3;
    }
  }

  &__error__box {
    @media screen and (width <= 640px) {
      position: relative;
    }

    position: absolute;
    bottom: -1px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
  }

  &__error {
    font-size: 12px;
    font-weight: 700;
    color: #e04f5f;

    & a {
      color: #005cc3;
    }
  }

  &__count {
    display: flex;
    flex: 1;
    justify-content: flex-end;

    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    color: #999;
  }
}
