@import "_generated-font-mixins.scss";
.mobile__button__message {
  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  height: 40px;
  margin: 15px 10px 10px;

  color: #fff;
  text-decoration: none;

  background-color: #006bb8;
  border: 1px solid #006bb8;
  border-radius: 4px;

  &::before {
    @include icon-message;

    margin: 6px 6px 0 0;
    font-size: 15px;
    color: #fff;
  }
}

.profile__button__wrapper {
  margin-bottom: 11px;
  padding: 20px 10px 10px;
}
