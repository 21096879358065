@import "_generated-font-mixins.scss";
.solo__image__slider {
  cursor: pointer;

  position: absolute;
  z-index: 1;
  top: 24.5%;

  line-height: normal;

  opacity: 0.75;
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0 0 10px 1px rgb(0 0 0 / 11%);

  &::before {
    font-size: 32px;
  }

  &:hover {
    opacity: 1;
  }

  &__left {
    @include icon-arrow-small-left;

    top: 50%;
    left: 0;
    margin-top: -16px;
    margin-left: 10px;
  }

  &__right {
    @include icon-arrow-small-right;

    top: 50%;
    right: 0;
    margin-top: -16px;
    margin-right: 12px;
  }
}

.multi__image__slider {
  cursor: pointer;

  position: absolute;
  z-index: 99;
  top: 24.5%;

  line-height: normal;

  opacity: 0.75;
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0 0 10px 1px rgb(0 0 0 / 11%);

  &::before {
    font-size: 32px;
  }

  &:hover {
    opacity: 1;
  }

  &__left {
    @include icon-arrow-small-left;

    left: 0;
    margin-left: 10px;
  }

  &__right {
    @include icon-arrow-small-right;

    right: 0;
    margin-right: 33px;
  }
}

.ad__slider {
  cursor: pointer;

  position: absolute;
  z-index: 99;
  top: 50%;
  transform: translateY(-50%);

  line-height: normal;

  opacity: 0.75;
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0 0 10px 1px rgb(0 0 0 / 11%);

  &::before {
    font-size: 32px;
  }

  &:hover {
    opacity: 1;
  }

  &__left {
    @include icon-arrow-small-left;

    left: 0;
    margin-left: 10px;
  }

  &__right {
    @include icon-arrow-small-right;

    right: 0;
    margin-right: 26px;
  }
}
