@import "_generated-font-mixins.scss";
.filter {
  &__icon {
    &::after {
      @include icon-filter-btn;

      font-size: 17px;
      color: #5a5a5a;
    }
  }

  &__button {
    position: absolute;
    z-index: 19000;
    top: 0;
    right: 0;

    width: 51px;
    height: 51px;
  }
}
