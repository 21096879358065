@import "_generated-font-mixins.scss";
.radio {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 7px;
    width: 100%;
  }

  &__input {
    pointer-events: none;
    position: absolute;
    opacity: 0;
  }

  &__label {
    position: relative;

    overflow: hidden;
    display: flex;
    gap: 7px;
    align-items: center;
    justify-content: space-between;

    box-sizing: border-box;
    width: 100%;
    min-height: 50px;
    padding: 7px 0;

    font-family: Helvetica, Arial, 'Liberation Sans', sans-serif;
    font-size: 15px;
    color: #555;

    background: #fff;
    border-bottom: solid 1px #e2e2e2;
    border-radius: 0;

    &::after {
      content: '';

      position: absolute;
      top: calc(50% - 8px);
      right: 0;

      box-sizing: border-box;
      width: 16px;
      height: 16px;

      border: 1px solid #b1b1b1;
      border-radius: 50%;
    }

    &:has(input:checked) {
      &::before {
        content: '';

        position: absolute;
        z-index: 3;
        top: calc(50% - 3px);
        right: 4.8px;

        box-sizing: border-box;
        width: 6px;
        height: 6px;

        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0 1px 0 0 #166dc9;
      }

      &::after {
        background: linear-gradient(180deg, #2b91fc 0, #3b99fc 100%);
        border: 1px solid #2384ea;
      }
    }
  }
}
