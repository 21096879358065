@import "_generated-font-mixins.scss";
.features {
  clear: both;
  overflow: hidden;

  margin: 0 0 5px;
  padding-left: 1px;

  font-size: 15px;
  line-height: 175%;

  &__right {
    float: left;
  }

  &__left {
    float: left;
    padding-right: 40px;
  }
}

.group {
  width: 350px;
  margin-bottom: 25px;
  list-style: none;

  & h2 {
    font-size: 17px;
    font-weight: 700;
    color: #a2a6ab;
    letter-spacing: -0.5pt;
  }

  &__key {
    position: relative;
    z-index: 1;

    float: left;
    overflow: hidden;

    max-width: 50%;

    text-overflow: ellipsis;
    white-space: nowrap;

    background: #fff;

    &.width {
      max-width: 100%;
    }
  }

  &__value {
    position: relative;
    z-index: 1;

    float: right;
    overflow: hidden;

    width: 176px;
    max-width: calc(50% - 4px) !important;
    min-height: 26px;
    padding-left: 4px;

    text-overflow: ellipsis;
    white-space: nowrap;

    background: #fff;
  }

  &__withdot {
    position: relative;
    z-index: 1;
    background: transparent;

    &::after {
      content: '';

      position: absolute;
      z-index: 0;
      right: 0;
      bottom: 8px;
      left: 0;

      display: inline-block;

      height: 1px;

      background-image: radial-gradient(circle, black 0, transparent 0.7px);
      background-repeat: repeat-x;
      background-size: 5px 100%;
    }
  }

  &__feature {
    overflow: hidden;
    margin-left: -1px;
    padding-left: 1px;

    & a {
      font-weight: normal;
      color: #2d82eb;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
