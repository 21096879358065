@import "_generated-font-mixins.scss";
.modal {
  @media (width <=639px) {
    width: 100%;
    margin-inline: 10px !important;
  }

  display: grid;
  gap: 5px;
  width: 100%;
  max-width: 500px;

  &__title {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 700;
  }

  &__buttons {
    @media (width <= 639px) {
      flex-direction: column-reverse;
      gap: 8px;
    }

    display: flex;
    gap: 16px;
    justify-content: space-between;

    width: 100%;
    margin-top: 15px;
  }
}

.button {
  @media (width <= 639px) {
    padding-block: 12px;
  }

  flex: 1;

  height: 48px;
  padding-inline: 16px;

  border: 1px solid;
  border-radius: 12px;

  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }

  &.cancel {
    color: #007bfb;
    border-color: #007bfb;
  }

  &.delete {
    color: #ff5a50;
    border-color: #ff5a50;
  }
}
