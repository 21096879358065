@import "_generated-font-mixins.scss";
.search {
  position: relative;
  flex: 1 1 auto;
  background-color: #fff;

  &__form {
    position: relative;

    &__submit {
      position: absolute;
      z-index: 2;
      top: 1px;
      right: 1px;

      width: 55px;
      height: 27px;

      font-size: 0;
      font-weight: 400;

      background: #f7f8fa;
      border-radius: 0 2px 2px 0;

      &:hover {
        cursor: pointer;
      }

      &::after {
        @include icon-search;

        font-size: 17px;
        color: #999;
      }
    }

    &__input {
      position: relative;
      z-index: 2;

      box-sizing: border-box;
      width: 100%;
      height: 29px;
      padding: 5px 110px 5px 10px;

      font-size: 15px;

      background-color: transparent;
      border: 1px solid #ccc;
      border-radius: 3px;

      &:focus-visible {
        border: 1px solid #006bb8;

        & + .search__form__submit {
          background: #006bb8;

          &::after {
            color: #fff;
          }
        }
      }

      &.hint {
        pointer-events: none;

        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        padding: 5px 110px 5px 10px;

        font-size: 15px;
        color: #ccc;

        background-color: transparent;
      }
    }
  }

  &__dropdown {
    position: absolute;
    z-index: 100;
    top: 29px;
    right: 0;
    left: 0;

    padding-block: 2px;

    font-size: 14px;

    background-color: #fff;
    border: 1px solid #ccc;
    border-top: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;

    &:empty {
      display: none;
    }

    &.loading {
      padding: 5px;
    }

    &__loading {
      margin-left: 10px;
      font-size: 14px;
      color: #999;
    }

    &__wrapper {
      margin-inline: 15px;
    }

    &__item {
      cursor: pointer;

      display: block;

      margin: 4px -8px;
      padding: 8px;

      font-size: 14px;
      line-height: 16px;
      color: #000;

      border-radius: 4px;

      transition: 0.1s;

      &__title {
        font-weight: 400;
        color: #005cc3;
        letter-spacing: 0;
      }

      &.users,
      &.category {
        color: #005cc3;
      }

      &:hover {
        background-color: #e0eafd;
      }

      &.hint {
        margin-inline: 8px;
      }
    }

    &__title {
      margin: 7px 0 15px;
      padding: 15px 0 0;

      font-size: 14px;
      line-height: 16px;
      color: #b5b5b5;

      border-top: 1px solid #e3e3e3;

      &.border {
        padding-top: 5px;
        border: 0;
      }
    }

    &__ad {
      cursor: pointer;

      position: relative;

      display: flex;
      align-items: center;

      margin: 4px 0 15px;
      padding: 10px;

      line-height: 16px;

      background: #fafafa;

      transition: 0.1s;

      &:last-child {
        margin-bottom: 12px;
      }

      &:hover {
        background-color: #e0eafd;

        & .search__dropdown__ad__price {
          color: #c01;
        }
      }

      & img {
        width: 80px;
        height: 62px;
        border-radius: 4px;
      }

      &__body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        max-width: calc(100% - 95px);
        margin-left: 10px;
      }

      &__title {
        overflow: hidden;
        max-height: 31px;
        margin-bottom: 2px;
        color: #005cc3;
      }

      &__price {
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        color: #000;
      }

      &__label {
        height: 19px;
        margin-left: 5px;
        padding: 4px 8px;

        font-size: 10px;
        line-height: 11px;
        color: #73b222;
        white-space: nowrap;

        background: rgb(115 178 34 / 20%);
        border-radius: 4px;
      }
    }

    &.mobile {
      top: 51px;
      overflow-y: auto;
      height: calc(100vh - 102px);
      padding: 0;

      & .search__dropdown__item:not(.hint) {
        margin: 0;
        padding: 13.5px 15px;
        border-bottom: 1px solid #e3e3e3;
      }

      & .search__dropdown__title {
        margin: 0;
        padding: 15px;
        border-top: 0;
      }

      & .search__dropdown__ad {
        padding: 8px 16px;
      }
    }
  }
}

.cancel__icon {
  cursor: pointer;

  position: absolute;
  z-index: 3;
  top: 3px;
  right: 63px;

  padding: 0 3px;

  visibility: hidden;
  opacity: 0;

  transition: all 0.5s ease;

  &::after {
    @include icon-search-close;

    font-size: 12px;
    font-weight: 100;
  }

  &.active {
    visibility: visible;
    opacity: 1;
    transition: all 0.5s ease;
  }
}

.booster {
  position: absolute;
  top: 15px;
  left: 15px;

  & ::after {
    top: 2px !important;
  }
}

.loader {
  display: grid;
  place-items: center;
  height: 85vh;
}

.separator {
  font-size: 14px;
  color: #999;
}
