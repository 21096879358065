@import "_generated-font-mixins.scss";
.category {
  max-width: 240px;
  margin-bottom: 12px;
  background-color: #f7f7f7;
  border-radius: 4px;

  & label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.category__title {
  cursor: pointer;

  position: relative;

  display: block;

  box-sizing: border-box;
  padding: 14px 30px 14px 15px;

  font-size: 15px;
  line-height: 17px;
  color: #333;

  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 4px;

  &::before {
    @include icon-chevron-down;

    position: absolute;
    top: 13px;
    right: 12px;

    display: block;

    width: 14px;
    height: 14px;

    font-size: 7px;
    color: #ccc;
    text-align: center;

    transition:
      transform 0.3s linear,
      top 0.3s linear;
  }

  &.expanded::before {
    top: 18px;
    transform: rotate(180deg);
  }

  &.hover__enabled:hover {
    background-color: #f7f7f7;
  }
}

.subcategories {
  padding-bottom: 18px;
}

.subcategory {
  margin-top: 18px;

  &__container {
    display: flex;
    gap: 4px;
    align-items: flex-end;
    padding: 0 15px;
  }

  & p {
    cursor: pointer;

    flex: 1;

    font-size: 15px;
    line-height: 21px;
    color: #333;

    &:hover {
      color: #d31311;
    }
  }

  & span {
    color: #ccc;
  }
}
