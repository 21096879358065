@import "_generated-font-mixins.scss";
.modal {
  // pointer-events: none;

  position: fixed;
  z-index: 19996;
  top: 0;
  left: 0;

  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;

  opacity: 0;
  background-color: rgb(0 0 0 / 80%);

  transition: 0.4s;

  &__content {
    cursor: default;

    position: relative;
    transform: scale(0.5);

    overflow: auto;

    width: max-content;
    min-width: 200px;
    max-height: 90%;
    margin: 0 20px;
    padding: 30px;

    font-size: 15px;

    background: #fff;
    border-radius: 10px;

    transition: 0.4s all;

    &.active {
      transform: scale(1);
    }

    &__closeButton {
      cursor: pointer;

      position: absolute;
      top: 10px;
      right: 5px;

      line-height: 1;

      &:is(:focus, :focus-visible) {
        border-radius: 10px;
        outline: 1px solid rgb(39 109 169 / 20%);
      }
    }
  }

  &.active {
    pointer-events: all;
    opacity: 1;
  }

  &.mobile {
    padding: 10px;

    & .modal__content {
      margin: 0;
      padding: 17px 20px;
    }
  }
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    @include icon-close;

    font-size: 32px;
    font-weight: 600;
    color: #888;
  }

  &:hover {
    &::before {
      color: inherit;
    }
  }
}
