@import "_generated-font-mixins.scss";
/* stylelint-disable */

.input {
  &__container {
    display: flex;
    gap: 7px;
    position: relative;

    &__vertical {
      flex-direction: column;
    }

    &__horizontal {
      flex-direction: row;
    }

    &__error {
      & + .input__label {
        color: red;
      }

      & + .input__input {
        border-color: red;
      }
    }

    &__full {
      grid-column: 1 / 4;
    }
  }

  &__label {
    color: #333;
    display: flex;
    font-size: 15px;
    font-weight: 400;
    gap: 3px;
  }

  &__required {
    color: #be1910;
  }

  &__head {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__tooltip {
    font-size: 13px;
    font-weight: 400;
    line-height: 16.9px;
    text-align: left;
    color: #666666;
  }

  &__capitalize {
    display: flex;
    align-items: center;
    background: #f3f3f3;
    border-radius: 4px;

    &__button {
      padding: 4px 10.5px;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #7d7d7d;
      cursor: pointer;
      border: none;
      background: 0 0;
    }
  }

  &__input {
    border: 1px solid #ced3d3;
    border-radius: 4px;
    color: #333;
    font-size: 15px;
    height: 28px;
    margin-bottom: 17px;
    min-height: 36px;
    padding: 0 8px !important;

    &:focus {
      border: 1px solid #ced3d3;
    }

    &__error {
      border: 1px solid red;
    }
  }

  &__box {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  &__error {
    bottom: -1px;
    color: red;
    font-size: 12px;
    font-weight: 400;
    position: absolute;
  }
}
