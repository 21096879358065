@import "_generated-font-mixins.scss";
.measurement {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;

  width: 100%;
  height: 100%;

  &__box {
    display: flex;
    flex-direction: column;
  }

  &__units {
    display: flex;

    box-sizing: border-box;
    width: fit-content;
    margin-bottom: 20px;

    font-size: 0;

    border: 1px solid #2d82eb;
    border-radius: 6px;
  }

  &__unit {
    padding: 10px 15px 9px;

    font-size: 15px;
    line-height: 17px;
    color: #555;
    text-align: center;
    letter-spacing: 0;

    &:has(input:checked) {
      color: #fff;
      background-color: #2d82eb;
    }

    & input {
      position: absolute;
      opacity: 0;
    }
  }

  &__input {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 0 14px;

    font-size: 15px;

    background-color: #fff !important;
    border: 1px solid #ccc;
    border-radius: 6px;

    &:focus-visible {
      border: 1px solid #ccc;
    }
  }

  &__error {
    padding-top: 5px;
    font-size: 15px;
    color: red;
  }
}
