@import "_generated-font-mixins.scss";
.carousel {
  min-height: 226px;
  margin-top: 20px;

  &:not(.has__title) {
    padding: 20px 0 0;
  }

  &__container {
    overflow: scroll;
    display: flex;

    width: 100%;
    height: 160px;
    margin: 0;
    padding-left: 10px;
  }

  &__item {
    width: 37% !important;
    margin-right: 10px;
  }

  &__title {
    width: 60%;
    margin: 15px 10px;
    padding: 0;

    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    color: #000;

    background-color: #dedede;
    border-radius: 4px;
  }
}
