@import "_generated-font-mixins.scss";
.select {
  &__container {
    position: relative;

    display: flex;

    box-sizing: border-box;
    min-height: 50px;
    padding: 7px 0;

    font-size: 15px;

    border-bottom: solid 1px #e2e2e2;

    &__vertical {
      flex-direction: column;
    }

    &__horizontal {
      flex-direction: row;
    }

    &__error {
      border-bottom: solid 1px red;
    }
  }

  &__label {
    display: flex;
    gap: 3px;

    width: 100%;
    margin-bottom: 3px;

    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    color: rgb(51 51 51 / 60%);

    &__large {
      width: 100%;
      margin: 0;
      padding: 10px 0;

      font-size: 15px;
      line-height: 1;
      color: rgb(51 51 51 / 60%);
    }

    &__error {
      color: red;
    }
  }

  &__required {
    color: #be1910;
  }

  &__box {
    position: relative;
    line-height: 18px;
    color: #555;
  }

  &__input {
    cursor: pointer;

    position: relative;

    width: 100%;
    height: 28px;
    min-height: 36px;
    padding: 0 8px !important;

    font-size: 15px;
    line-height: 18px;
    color: #555;

    appearance: none;
    background: linear-gradient(to top, #f2f2f2, #fff);
    border: 1px solid #a2acac;
    border-radius: 4px;

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.6;
      background: #f2f2f2;
    }
  }
}

.drawer {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 16px;
}
