@import "_generated-font-mixins.scss";
.table {
  overflow-y: auto;

  max-height: 300px;
  margin: 15px 0;

  border: 1px solid #ccc;
  border-radius: 4px;

  & table {
    width: 100%;

    & tr {
      & td {
        text-align: left;
        vertical-align: middle;
      }
    }
  }
}

.help {
  font-size: 11px;
  opacity: 0.7;
}

.loader {
  margin-block: 10px;
}
