@import "_generated-font-mixins.scss";
.modal {
  width: auto;
  max-width: 430px !important;

  &__header {
    margin-bottom: 20px;

    & h3 {
      @media screen and (width <= 640px) {
        font-size: 16px;
        font-weight: 700;
        line-height: 18px;
        color: #000;
        letter-spacing: 0.5px;
      }

      font-size: 22px;
      font-weight: 700;
    }
  }

  &__body {
    letter-spacing: 0.5px;

    & p {
      @media screen and (width <= 640px) {
        color: #767676;
      }

      margin-bottom: 10px;
    }

    &__description {
      font-size: 11px;
      opacity: 0.7;
    }

    &__label {
      @media screen and (width <= 640px) {
        color: #767676;
      }

      display: flex;
      flex-direction: row;
      gap: 12px;
      align-items: center;
    }

    &__footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-top: 20px;

      & button {
        margin-right: 10px;
      }
    }
  }
}
