@import "_generated-font-mixins.scss";
.sort__modal {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  transform: translateX(100%);

  width: 100%;
  height: 100%;

  opacity: 0;
  background: #fff;

  transition:
    transform 200ms ease,
    opacity 200ms ease;

  &.open {
    transform: translateX(0);
    opacity: 1;
  }

  &__content {
    display: flex;
    justify-content: center;

    box-sizing: border-box;
    width: 100%;
    padding: 14px;

    font-size: 16px;

    background-color: #fff;

    &__title {
      font-weight: 700;
      color: #333;
    }

    &__icon__back {
      position: absolute;
      top: 1px;
      left: 3px;

      width: 51px;
      height: 51px;

      &::after {
        @include icon-arrow-back;

        font-size: 20px;
        color: #6e6e6e;
      }
    }

    &__icon__close {
      position: absolute;
      top: 2px;
      right: -1px;

      width: 51px;
      height: 51px;

      &::before {
        @include icon-cancel;

        font-size: 18px;
        color: #6e6e6e;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-top: -3px;

    &__list__item {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      padding: 13px 19px 13px 15px;

      border-bottom: 1px solid #e2e2e2;

      &__title {
        font-size: 15px;
        color: #333;
      }

      &__radio {
        position: absolute;
        right: 14px;

        width: 23px;
        height: 23px;

        appearance: none;
        background-color: transparent;
        border: 1px solid #b1b1b1;
        border-radius: 11px;

        &:checked {
          background: #3b99fc;
          border-color: #3b99fc;

          &::after {
            content: '';

            position: absolute;
            top: 6px;
            left: 6px;

            display: block;

            width: 9px;
            height: 9px;
            margin: auto;

            background-color: #fff;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
