@import "_generated-font-mixins.scss";
.number {
  position: relative;

  display: flex;
  align-items: center;

  margin-right: 0;
  padding-right: 40px;

  font-size: 21px;
  font-weight: 700;
  color: #000;
  white-space: nowrap;

  &::after {
    content: '';

    position: absolute;
    top: 12px;
    right: 16px;

    display: flex;

    width: 8px;
    height: 8px;

    font-size: 24px;
    line-height: 19px;

    background-color: #cacccf;
    border-radius: 50%;
  }

  &__hidden {
    display: flex;
    align-items: center;

    & i {
      width: 6px;
      height: 6px;
      margin: 0 2px;

      background-color: #000;
      border-radius: 50%;
    }
  }
}

.number:last-of-type::after {
  content: none;
}

.number:nth-of-type(3n)::after {
  content: none;
}
