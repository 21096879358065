@import "_generated-font-mixins.scss";
.view {
  &__main {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
    background: #f5f5f5;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  &__form {
    width: 100%;
  }
}
