@import "_generated-font-mixins.scss";
/* stylelint-disable selector-class-pattern */

.errorPage {
  @media screen and (width <= 992px) {
    width: 100%;
    margin-top: 20px;

    & .errorPage__info {
      flex-direction: column;
      align-items: center;
      max-width: 300px;
      margin: 0 auto 30px;

      & img {
        max-width: 100px;
        margin-right: 60px;
      }

      &::after {
        right: 20%;
        bottom: 25%;
      }
    }

    & .errorPage__title {
      margin-top: 15px;

      & b {
        position: absolute;
        right: 17%;
        bottom: 27%;
        font-size: 30px;
      }

      & span {
        font-size: 20px;
        line-height: 1.2;
      }
    }

    & .errorPage__search {
      margin: 0 0 0 15px;
    }
  }

  width: 750px;
  margin: 11% auto 0;

  &__info {
    position: relative;

    display: flex;
    align-items: flex-end;

    width: 100%;
    margin-bottom: 55px;

    &::after {
      content: '';

      position: absolute;
      right: 0;
      bottom: 34px;

      display: block;

      width: 83px;
      height: 40px;

      background: url('/static/ball.png');
    }
  }

  &__title {
    display: flex;
    align-items: center;
    margin-left: -20px;
    color: #474c59;

    & b {
      font-size: 120px;
      font-weight: 700;
      line-height: 1.4;
    }

    & span {
      max-width: 250px;
      margin-left: 15px;
      font-size: 46px;
      line-height: 54px;
    }
  }

  &__search {
    display: flex;
    gap: 30px;
    align-items: center;
    margin: 35px;
  }

  &.mobile {
    width: 100%;
    margin-top: 20px;

    & .errorPage__info {
      flex-direction: column;
      align-items: center;
      max-width: 300px;
      margin: 0 auto 30px;

      & img {
        max-width: 100px;
        margin-right: 60px;
      }

      &::after {
        right: 20%;
        bottom: 25%;
      }
    }

    & .errorPage__title {
      margin-top: 15px;

      & b {
        position: absolute;
        right: 17%;
        bottom: 27%;
        font-size: 30px;
      }

      & span {
        font-size: 20px;
        line-height: 1.2;
      }
    }

    & .errorPage__search {
      margin: 0 0 0 15px;
    }
  }
}
