@import "_generated-font-mixins.scss";
.promotemodal {
  position: fixed;
  z-index: 1002;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: flex-end;

  width: 100%;
  height: 100%;

  background: rgb(0 0 0 / 75%);

  -ms-flex-align: end;

  &__items {
    width: 100%;
    background-color: white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    &__item {
      display: flex;
      align-items: center;
      min-height: 50px;
      padding: 0 12px;

      &.inactive {
        opacity: 0.6;

        // pointer-events: none;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #e2e2e2;
      }

      &__icon {
        position: relative;

        width: 24px;
        height: 24px;
        margin-right: 10px;

        color: gray;
      }

      &__body {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: flex-start;

        & > span {
          font-size: 15px;
          color: #333;
        }
      }

      &__badge {
        margin-top: -1px;
        padding: 5px 12px 3.12px;

        font-size: 10px;
        font-weight: 400;
        font-style: normal;
        line-height: 11px;

        border-radius: 4px;
      }
    }
  }
}

.ilabel {
  top: -4px;
  left: 5px;
  font-size: 22px;
}

.ibooster {
  top: -14px;
  left: -2px;
  font-size: 35px;
}

.ipackage {
  top: -5px;
  left: 4px;
}

.ihighlight {
  top: -7px;
  left: 4px;
  font-size: 25px;
}

.iautorefresh {
  top: -5px;
  font-size: 25px;
}

.iadrepublish {
  top: -18px;
  left: -3px;
  font-size: 39px;
}

.subtitle {
  display: block;

  margin-top: 2px;

  font-size: 12px !important;
  line-height: 14px;
  color: #000;

  opacity: 0.4;
}
