@import "_generated-font-mixins.scss";
.phone__add {
  cursor: pointer;

  display: inline-block;

  margin: 10px 0 20px;
  padding-bottom: 1px;

  font-size: 12px;
  color: #005cc3;
  vertical-align: middle;

  border-bottom: dotted 1px;

  &:hover {
    color: #d02a25;
  }

  &__mobile {
    margin: 20px 0 30px;
  }

  &__form {
    display: flex;
    gap: 15px;
    align-items: flex-end;

    margin-bottom: 20px;
    padding-top: 20px;

    &__item {
      & label {
        display: block;
        margin-bottom: 3px;
        margin-left: 1px;
        font-size: 12px;
      }

      & input,
      & select {
        box-sizing: border-box;
        min-height: 36px;
        padding: 0 8px;

        font-size: 15px;
        color: #555;

        border: 1px solid #ced3d3;
        border-radius: 4px;

        transition: border-color 0.2s;

        &:focus {
          border-color: #a2acac;
        }
      }

      & select {
        background:
          100% 50% / 24px 24px no-repeat,
          linear-gradient(to top, #f2f2f2, #fff);
      }

      &:first-child {
        & select {
          max-width: 150px;
        }
      }
    }

    &__cancel {
      padding: 7px 0;
    }
  }
}
