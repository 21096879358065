@import "_generated-font-mixins.scss";
.promote {
  display: flex;
  margin: 5px 10px 10px 140px;

  &.single {
    justify-content: center;
    justify-self: inherit;
    width: 100%;
    margin: 5px 10px 10px 0;
  }

  & > button > i {
    margin-right: 14px;
    font-size: 18px !important;
  }
}
