@import "_generated-font-mixins.scss";
.wrapper {
  display: flex;
}

.first__image {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: auto;
}

.image {
  position: absolute;
  top: 0;
  left: 100%;

  width: 100%;
  height: auto;

  &.animated__image {
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
}

@keyframes fade {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fade__anim {
  animation: fade 0s ease 2s forwards;
}
