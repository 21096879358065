@import "_generated-font-mixins.scss";
.search {
  position: relative;
  padding-bottom: 10px;

  &__input {
    width: 100%;
    height: 30px;
    padding-right: 0;
    padding-left: 33px;
    padding-block: 4px;

    font-size: 15px;
    line-height: 30px;

    border: 1px solid #ced3d3;
    border-radius: 5px;

    &:focus {
      border: 1px solid #ced3d3;
    }

    &::placeholder {
      font-size: 15px;
      color: #888;
    }
  }

  &.mobile {
    & .search__input {
      height: 47px;
      padding-left: 40px;
    }
  }

  &.cabinet__ads {
    width: calc(100% - 20px);
    margin: 8px 10px;
    padding-bottom: 0;

    & .search__input {
      height: 40px;
      padding-right: 40px;
      padding-left: 40px;

      &::placeholder {
        font-weight: 300;
        color: #333;
        opacity: 0.4;
      }
    }
  }
}

.icon {
  &__search {
    &::before {
      @include icon-search-btn;

      position: absolute;
      top: 5px;
      left: 12px;

      font-size: 15px;
      color: #aaa;
    }
  }

  &__close {
    &::before {
      @include icon-close;

      cursor: pointer;

      position: absolute;
      right: 5px;

      font-size: 30px;
      line-height: 1;
      color: #bbb;
    }
  }

  &__cabinet__ads {
    &::before {
      @include icon-search;

      position: absolute;
      top: 5px;
      left: 12px;

      font-size: 21px;
      color: #adadad;
    }
  }
}

.mobile {
  & .icon {
    &__search {
      &::before {
        top: 10px;
        font-size: 18px;
      }
    }

    &__close {
      &::before {
        top: 5px;
        font-size: 35px;
      }
    }
  }
}

.delete__ads {
  position: absolute;
  top: 10px;
  right: 12px;

  width: 18px;
  height: 18px;

  opacity: 0.3;
  background-color: #000;
  border-radius: 50%;

  &::before {
    bottom: -5px;
    left: -5px;
    font-size: 28px;
    color: #fff;
  }
}
