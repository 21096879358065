@import "_generated-font-mixins.scss";
.warning__confirm {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 600px;

  & h3 {
    font-size: 20px;
    line-height: 23px;
    color: #333;
  }

  & p {
    font-size: 15px;
    color: #454c59;
  }

  &__footer {
    display: flex;
    gap: 15px;
  }
}
