@import "_generated-font-mixins.scss";
.packages__confirm {
  width: 100%;
  max-width: 370px;
  text-align: left;

  & h3 {
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: 700;
    color: #000;
  }

  & p {
    margin-bottom: 20px;

    font-size: 11px;
    line-height: 15px;
    color: #555;

    opacity: 0.7;
  }

  &__footer {
    display: flex;
    align-items: center;
    padding: 20px 0 0;
    border-top: dotted 1px #ccc;

    & button + button {
      margin-left: 15px;
    }
  }

  &.mobile {
    & h3 {
      font-size: 16px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0.5px;
    }

    & .packages__confirm__footer {
      flex-direction: row-reverse;
      padding: 0;
      border-top: 0;

      & button:first-child {
        margin-left: 25px;
        font-weight: 700;
      }
    }
  }
}
