@import "_generated-font-mixins.scss";
.user__control {
  width: 240px;
  margin-bottom: 5px;
  padding: 20px 13px 8px 20px;

  font-size: 15px;
  line-height: 17px;

  background: #f6f6f8;
  border-radius: 4px;

  &__item {
    margin-bottom: 12px;
  }

  &__separator {
    margin: 20px 0;
    border-top: 1px solid #e0e0e0;
  }
}

.link {
  cursor: pointer;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 207px;
  height: 24px;
  padding-left: 33px;

  line-height: 24px;
  color: #000;

  &:not(.disabled) {
    &:hover {
      color: #005cc3;
    }
  }

  & i {
    position: absolute;
  }

  &__help {
    display: block;

    width: auto;
    margin: -4px 0 0;

    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    color: #9c9c9c;
    text-align: start;
  }

  &__label {
    position: absolute;
    top: calc(50% - 8px);
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 30px;
    height: 16px;

    line-height: 11px;

    border-radius: 4px;

    &.label__new {
      font-size: 8px;
      color: #fff;
      background-color: #f08019;
    }

    &.label__on {
      top: 6px;
      font-size: 10px;
      color: #649c1e;
      background: rgb(100 156 30 / 20%);
    }
  }

  &__package {
    position: absolute;
    top: 27px;
    left: 0;
    transform: translateX(-50%);

    margin-left: 7%;

    font-size: 10px;
    font-weight: 400;
    font-style: normal;
    line-height: 11px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
  }
}

.basic {
  color: #6da4ff;
}

.lite {
  color: #ffc253;
}

.plus {
  color: #76b634;
}

.turbo {
  color: #be3e55;
}

.autorefresh {
  left: -6px;
  font-size: 27px;
  color: #aac544;
}

.text {
  display: flex;
  align-items: center;

  &__blocked {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.booster {
  left: -6px;
  font-size: 34px;
  color: #0058c5;

  &__hide {
    left: -6px;
    font-size: 34px;
    color: rgb(131 128 128);
  }
}

.highlight {
  left: 0;
  font-size: 25px;
  color: #e86238;

  &__hide {
    left: 0;
    font-size: 25px;
    color: rgb(131 128 128);
  }
}

.label {
  left: 1px !important;
  font-size: 23px;
  color: #ffae28;

  &__hide {
    left: 1px !important;
    font-size: 23px;
    color: rgb(131 128 128);
  }
}

.widecard {
  left: 1px;
  font-size: 23px;
  color: #e86238;

  &__hide {
    left: 1px;
    font-size: 23px;
    color: rgb(131 128 128);
  }
}

.republish {
  left: -7px;
  font-size: 39px;
  color: #609dd0;

  &__hide {
    left: -7px;
    font-size: 39px;
    color: rgb(131 128 128);
  }
}

.package {
  left: 1px;
  font-size: 23px;
}

.bargaining {
  left: 3px;
  font-size: 23px;
  color: #65696d !important;
}

.accepted {
  left: 3px;
  font-size: 23px;
  color: #ef4926;
}

.hide {
  left: -5px;
  font-size: 36px;
  color: #65696d;
}

.edit {
  left: -7px;
  font-size: 38px;
  color: #65696d;
}

.print {
  left: 3px;
  font-size: 20px;
  color: #65696d;
}

.actions {
  left: 4px;
  font-size: 14px;
  color: #65696d;
}

.remove {
  left: 4px;
  font-size: 20px;
  color: #65696d;
}

.statistics {
  left: -6px;
  font-size: 27px;
  color: #65696d;
}

.weekday {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 -3px;
}

.eye {
  left: 3px;
  font-size: 17px;
  color: #f7c874;
}

.social {
  cursor: pointer;

  position: relative;

  width: 103px;
  height: 20px;
  padding: 4px 9px 4px 26px;

  font-size: 11px;
  font-weight: bold;
  line-height: 1.28;
  color: #ffff;
  vertical-align: bottom;

  background: #1877f2;
  border-radius: 3px;

  & i {
    position: absolute;
    top: 1px;
    left: 8px;

    font-size: 20px;
    color: #ffff;
  }
}

.disabled {
  cursor: not-allowed;
  color: rgb(131 128 128);
}

.blocked {
  top: 0;
  left: -4px;

  width: 40px;
  height: 24px;

  background-image: url('/static/images/blocked_eye.svg');
  background-repeat: no-repeat;
}

.modal {
  &.autorepublish {
    padding: 0;
  }
}
