@import "_generated-font-mixins.scss";
.booster {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;

  width: 21px;
  height: 20px;

  text-align: center;

  background: rgb(0 0 0 / 40%);
  border-radius: 2px;

  &::after {
    @include icon-booster;

    position: absolute;
    top: -5px;
    left: 0;

    font-size: 20px;
    color: #fff;
  }
}

.tooltip {
  --rt-opacity: 1;

  z-index: 999;
  padding: 0;
  font-size: 14px !important;
}

.link {
  color: #3093f9;
}
