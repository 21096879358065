@import "_generated-font-mixins.scss";
.infocar {
  max-width: 720px;
  margin-bottom: 30px;
  padding: 30px;

  border: 1px solid #e3e3e3;
  border-radius: 4px;

  &__top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 25px;

    &__title {
      margin-bottom: 10px;
      font-size: 24px;
      font-weight: 700;
      line-height: 27px;
    }

    &__subtitle {
      font-size: 15px;
      font-weight: 400;
      line-height: 17px;
      color: #999;
    }

    &__logo {
      width: 74px;
      height: 26px;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__buttons {
    display: flex;
    gap: 24px;
    align-items: center;
    margin-top: 15px;

    &__general {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 42px;
      padding: 0 32px;

      border-radius: 4px;
    }

    &__order {
      color: #fff;
      background-color: #3164be;
    }

    &__example {
      color: #3164be;
      border: 1px solid #3164be;
    }
  }
}
