@import "_generated-font-mixins.scss";
.features {
  @media screen and (width <= 640px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    padding: 20px 0;
  }

  display: flex;
  flex-direction: column;

  width: 360px;

  font-size: 15px;
  line-height: 175%;

  & h2 {
    @media screen and (width <= 640px) {
      font-size: 18px;
      line-height: 23px;
      color: #333;
    }

    display: inline;

    font-size: 17px;
    font-weight: 700;
    color: #a2a6ab;
    letter-spacing: -0.5pt;
  }

  &__link {
    @media screen and (width <= 650px) {
      margin-top: 0;
      text-align: center;
    }

    display: inline-block;
    align-self: baseline;

    margin-top: 5px;
    padding: 10px;

    font-size: 15px;
    font-weight: 400;
    line-height: 17px;
    color: #2f80ed;

    background: rgb(59 117 255 / 8%);
    border-radius: 4px;
  }
}
