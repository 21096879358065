@import "_generated-font-mixins.scss";
@keyframes skeleton-loading {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}

.skeleton__animate {
  animation: skeleton-loading 1.5s infinite ease-in-out;
}
