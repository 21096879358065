@import "_generated-font-mixins.scss";
.wrapper {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 60px;
  margin-bottom: 10px;
  padding: 20px;

  border: 1px solid #ebebeb;
  border-radius: 4px;
}

.favorite {
  margin-left: 4px;

  &__empty {
    &::before {
      @include icon-heart;

      font-size: 13px;
      color: #555;
    }

    &:hover {
      &::before {
        opacity: 0.7;
      }
    }
  }

  &__fill {
    &::before {
      @include icon-fill-heart;

      font-size: 13px;
      color: #ef4726;
    }

    &:hover {
      &::before {
        opacity: 0.8;
      }
    }
  }
}

.features {
  display: flex;
  gap: 5px;
  margin-top: 10px;

  &:empty {
    display: none;
  }

  &__item {
    padding: 6px 12px;

    font-size: 13px;
    line-height: 15px;
    color: #333;
    text-align: center;

    background-color: rgb(24 27 30 / 6%);
    border-radius: 4px;
  }
}

.title {
  display: flex;

  font-size: 15px;
  line-height: 17px;
  color: #0058c5;
  word-wrap: break-word;

  &__link {
    display: inline;

    &:hover {
      color: #c01;
    }
  }
}

.booster {
  position: absolute;
  right: 40px;
}
