@import "_generated-font-mixins.scss";
.container {
  position: relative;
  height: 87vh;
  padding: 0 20px;
  background: #f5f5f5;

  &__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 1000px;
  margin: 20px 0;

  &.wrapper__mobile {
    align-items: center;
    width: 100%;
    margin: 0;
  }

  &__title {
    margin-top: 0;
    font-size: 17px;
    font-weight: 400;
    color: #333;
  }

  &__add__number {
    cursor: pointer;

    margin-top: 8px;

    font-size: 12px;
    color: #005cc3;

    border-bottom: dotted 1px;

    &.add__number__mobile {
      display: block;

      box-sizing: border-box;
      width: 100%;
      height: 40px;
      margin-top: 20px;
      padding: 0 20px 30px 0;

      font-size: 14px;
      line-height: 38px;
      color: #006bb8;
      text-align: center;
      text-decoration: none;

      border: 1px solid #006bb8;
      border-radius: 4px;
    }
  }
}
