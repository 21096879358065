@import "_generated-font-mixins.scss";
.tooltip {
  &__body {
    display: flex;
    flex-direction: column;
    gap: 15px;

    width: 100%;
    height: 100%;
    margin-bottom: 40px;

    &::after {
      content: '';

      position: absolute;
      bottom: -11px;
      left: 20px;

      display: block;

      width: 0;
      height: 0;

      border-color: #e0e0e0 transparent transparent;
      border-style: solid;
      border-width: 9px 7.5px 0;
    }
  }

  &__input {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;

    & textarea {
      resize: none;

      box-sizing: border-box;
      width: 100%;
      height: 92px;
      padding: 11px 12px;

      font-family: Menlo, sans-serif;
      font-size: 15px;
      line-height: 17px;
      color: #555;

      border: 1px solid #ccc;
      border-radius: 4px;
      outline: 0;

      &::placeholder {
        color: #555;
      }
    }
  }

  &__providers {
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;

    &__vimeo {
      &::before {
        @include icon-vimeo-icon;

        color: #ececef;
      }
    }

    &__youtube {
      &::before {
        @include icon-youtube-icon;

        color: #ececef;
      }
    }
  }

  &__submit {
    cursor: pointer;

    width: 100%;
    height: 29px;

    font-size: 17px;
    font-weight: 400;
    color: #fff;
    text-align: center;

    background: #3560b9;
    border: none;
    border-radius: 3px;
  }
}

.submit__disabled {
  cursor: default;
  background: #aaadb5;
}
