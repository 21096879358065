@import "_generated-font-mixins.scss";
.wrapper {
  width: 100%;

  &.wrapper__mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 4px 0;

    border-top: solid 2px #fff;

    &:last-of-type {
      border-bottom: solid 2px #fff;
    }
  }

  &__phone {
    float: left;
    min-width: 160px;
    margin: 8px 0 0;

    &.phone__mobile {
      min-width: 125px;
      margin: 0 14px 0 0;

      &.not__confirmed {
        opacity: 0.6;
      }
    }

    &__envelope {
      cursor: pointer;
      font-size: 18px;
      font-weight: 700;
      color: #000;

      &.envelope__mobile {
        font-size: 14px;
      }

      &__code {
        font-weight: 400;
        color: #999;
      }
    }
  }

  &__actions {
    float: left;
    margin: 10px 0 0 20px;
    font-size: 13px;
    color: #919599;

    &.actions__mobile {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;

      margin: 0;
    }

    &__confirmed {
      position: relative;
      left: 10px;

      display: inline-block;

      width: 140px;
      margin-right: 19px;

      font-style: italic;
      color: #abb354;

      &.confirmed__ro {
        left: 4px;
      }
    }

    &__requires__confirmation {
      width: 140px;
      margin-right: 19px;

      line-height: 1.3;
      color: #005cc3;

      opacity: 0.7;
      border-bottom: dotted 1px;

      &.requires__confirmation__mobile {
        line-height: 0.5;
        color: #2d82eb;
        text-decoration-line: underline;
        text-underline-offset: 2px;

        opacity: 1;
        border-bottom: none;
      }
    }

    &__delete {
      cursor: pointer;
      display: inline-block;
      margin-right: 15px;

      &.delete__mobile {
        margin-right: 0;
      }

      &__text {
        display: inline-block;

        line-height: 1.3;
        color: #b50009;
        text-decoration: none;

        opacity: 0.7;
        border-bottom: dotted 1px;

        transition: opacity 0.2s;
      }

      &__icon {
        width: 14px;

        &::after {
          @include icon-delete-number;

          font-size: 21px;
          color: #ed676e;
        }
      }
    }

    &__need__confirmation {
      display: inline-block;
      margin-left: 3px;
      font-style: italic;
      color: #919599;
    }
  }
}
