@import "_generated-font-mixins.scss";
.price {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;

  width: 100%;
  height: 100%;

  &__box {
    display: flex;
    flex-direction: column;
  }

  &__modes {
    overflow: hidden;
    display: flex;

    box-sizing: border-box;
    width: 100%;
    margin-bottom: 20px;

    font-size: 0;

    border: 1px solid #2d82eb;
    border-radius: 6px;
  }

  &__mode {
    width: 50%;
    padding: 10px 15px 9px;

    font-size: 15px;
    line-height: 17px;
    color: #555;
    text-align: center;
    letter-spacing: 0;

    & span {
      text-transform: capitalize;
    }

    &:has(input:checked) {
      color: #fff;
      background-color: #2d82eb;
    }

    & input {
      position: absolute;
      opacity: 0;
    }
  }

  &__main {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }

  &__units {
    overflow: hidden;
    display: flex;

    box-sizing: border-box;
    width: calc(50% - 10px);

    font-size: 0;

    border: 1px solid #2d82eb;
    border-radius: 6px;
  }

  &__unit {
    width: calc(100% / 3);
    padding: 10px 0 9px;

    font-size: 15px;
    line-height: 17px;
    color: #555;
    text-align: center;
    letter-spacing: 0;

    &:not(:last-child) {
      border-right: 1px solid #2d82eb;
    }

    &:has(input:checked) {
      color: #fff;
      background-color: #2d82eb;
    }

    & input {
      position: absolute;
      opacity: 0;
    }
  }

  &__input {
    display: flex;
    align-items: center;

    box-sizing: border-box;
    width: calc(50% - 10px);
    height: 40px;
    padding: 0 14px;

    font-size: 15px;

    background-color: #fff !important;
    border: 1px solid #ccc;
    border-radius: 6px;

    &:focus-visible {
      border: 1px solid #ccc;
    }

    &__prefix {
      display: flex;
      align-items: center;

      margin-right: 10px;

      font-size: 15px;
      font-weight: 400;
      color: rgb(51 51 51 / 50%);
      text-align: left;
      white-space: nowrap;
    }

    & input {
      display: flex;
      width: 100%;
    }
  }

  &__measurement {
    width: 100%;

    &__input {
      box-sizing: border-box;
      width: 100%;
      height: 40px;
      padding: 0 14px;

      font-size: 15px;

      background-color: #fff !important;
      border: 1px solid #ccc;
      border-radius: 6px;
    }
  }
}
