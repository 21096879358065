@import "_generated-font-mixins.scss";
.wrapper {
  position: relative;

  box-sizing: border-box;
  width: calc(100% + 32px);
  margin-left: -16px;
  padding: 20px;

  background-color: rgb(219 237 255 / 50%);
  border-radius: 4px;
}

.chat__label {
  cursor: pointer;

  position: relative;

  display: flex;
  align-items: center;

  font-size: 15px;
  font-weight: 700;
  line-height: 20px;

  &.mobile {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.chat__title {
  margin-left: 6px;
}

.chat__text {
  margin: 14px 0 0;
  font-size: 15px;
  line-height: 17px;
}

.chat__icon {
  width: 15px;
  height: 15px;
  margin-left: 5px;
  background: url('/static/images/chat.svg') center center no-repeat;
}
