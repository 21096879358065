@import "_generated-font-mixins.scss";
.group {
  &__container {
    display: flex;
    grid-column: 1 / 4;
    gap: 7px;
    margin-bottom: 17px;

    &__vertical {
      flex-direction: column;
    }

    &__horizontal {
      flex-direction: row;
    }
  }

  &__label {
    display: flex;
    gap: 3px;

    font-size: 15px;
    font-weight: 400;
    color: #333;
  }

  &__required {
    color: #be1910;
  }

  &__box {
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    align-items: center;

    width: 100%;
    padding: 0 16px;
  }
}
