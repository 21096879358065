@import "_generated-font-mixins.scss";
.wrapper__sort {
  cursor: pointer;

  display: flex;
  justify-content: space-between;

  width: 100%;
  padding: 11px 10px 9px 17px;

  font-size: 16px;
  color: #333;

  border-bottom: 1px #e2e2e2 solid;

  &__title {
    padding-right: 50px;
    line-height: 25px;
  }

  &__arrow {
    &::after {
      @include icon-arrow-right;

      font-size: 18px;
      color: #999;
    }
  }
}
