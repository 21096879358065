@import "_generated-font-mixins.scss";
.upload {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 7px;

    aspect-ratio: 1;
    width: 100%;
    margin-right: 14px;
  }

  &__view {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    height: 100%;
  }

  &__open {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0;

    vertical-align: top;

    appearance: none;
    border: 1px solid #2d82eb;
    border-radius: 4px;

    &::after {
      @include icon-camera-plus;

      position: absolute;
      top: 55%;
      left: 49%;
      transform: translate(-50%, -50%);

      font-size: 28px;
      color: rgb(45 130 235);
    }
  }
}

.drawer {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 16px;
}
