@import "_generated-font-mixins.scss";
.search__content {
  position: fixed;
  z-index: 12;
  top: 0;
  right: 0;
  transform: translateX(100%);

  overflow: hidden auto;

  width: 100%;
  height: calc(100vh - 50px);

  opacity: 0;
  background: #fff;

  transition:
    transform 200ms ease,
    opacity 200ms ease;

  &.open {
    transform: translateX(0);
    opacity: 1;
  }

  &__title {
    display: flex;
    justify-content: center;

    box-sizing: border-box;
    width: 100%;
    padding: 14px;

    font-size: 16px;

    background-color: #fff;

    &__search {
      font-weight: 700;
      color: #333;
    }

    &__icon {
      position: absolute;
      top: 2px;
      right: 1px;

      width: 51px;
      height: 51px;

      &::before {
        @include icon-cancel;

        font-size: 18px;
        color: #6e6e6e;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;

    &.hide {
      opacity: 0;
    }

    &__category {
      cursor: pointer;

      display: flex;
      justify-content: space-between;

      width: 100%;
      padding: 11px 10px 11px 17px;

      font-size: 16px;
      color: #333;

      border-bottom: 1px #e2e2e2 solid;

      &__title {
        padding-right: 50px;
        line-height: 25px;
      }

      &__count {
        display: flex;
        gap: 9px;
        align-items: center;

        font-size: 15px;
        color: #999;

        &::after {
          @include icon-arrow-right;

          font-size: 18px;
          color: #999;
        }
      }
    }
  }
}
