@import "_generated-font-mixins.scss";
.measurement {
  &__container {
    position: relative;
    display: flex;
    gap: 7px;

    &__vertical {
      flex-direction: column;
    }

    &__horizontal {
      flex-direction: row;
    }
  }

  &__label {
    display: flex;
    gap: 3px;

    font-size: 15px;
    font-weight: 400;
    color: #333;
  }

  &__required {
    color: #be1910;
  }

  &__head {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__tooltip {
    font-size: 13px;
    font-weight: 400;
    line-height: 16.9px;
    color: #666;
    text-align: left;
  }

  &__box {
    display: flex;
    gap: 7px;
  }

  &__input {
    display: flex;
    flex: 1;

    height: 28px;
    min-height: 36px;
    margin-bottom: 17px;
    padding: 0 8px !important;

    font-size: 15px;
    color: #333;

    border: 1px solid #ced3d3;
    border-radius: 4px;

    &:focus {
      border: 1px solid #ced3d3;
    }

    &__error {
      border: 1px solid red;
    }
  }

  &__select {
    min-width: 70px;
    height: 28px;
    min-height: 36px;
    padding: 0 15px 0 8px !important;

    font-size: 15px;
    color: #555;

    appearance: none;
    background: linear-gradient(to top, #f2f2f2, #fff);
    border: 1px solid #a2acac;
    border-radius: 4px;

    &__box {
      position: relative;
      min-width: 70px;
      margin-bottom: 17px;

      &::before {
        content: '';

        position: absolute;
        z-index: 1;
        top: 0;
        right: 10px;
        bottom: 0;
        transform: translateY(-1px);

        display: flex;

        width: 0;
        height: 0;
        margin: auto;

        border-top: 3.5px solid #000;
        border-right: 3.5px solid transparent;
        border-left: 3.5px solid transparent;
      }
    }
  }

  &__error {
    position: absolute;
    bottom: -1px;

    font-size: 12px;
    font-weight: 400;
    color: red;
  }
}
